<template>
  <div class="container">
    <div class="top_container">
      <span>维修历史</span>
    </div>
    <div class="wrapper" ref="wrapper">
      <div class="content">
        <div class="pulldown-wrapper loading_text">
          <div v-show="beforePullDown">
            <span>下拉刷新数据</span>
          </div>
          <div v-show="!beforePullDown">
            <div v-show="isPullingDown">
              <span>加载中...</span>
            </div>
            <div v-show="!isPullingDown">
              <span>刷新成功</span>
            </div>
          </div>
        </div>
        <template v-if="orderList.length > 0">
          <div v-for="order in orderList" :key="order.id" class="history_item">
            <div class="top">
              <span class="code">{{ order.code }}</span>
            </div>
            <div class="middle">
              <div class="middle_item">
                <span class="title">处理人</span>
                <span class="value">{{ order.handler ? order.handler.real_name : '' }}</span>
              </div>
              <div class="middle_item">
                <span class="title">故障描述</span>
                <span class="value">{{ order.profile }}</span>
              </div>
              <extend-field-list :field-list="order.fieldValueList" className="middle_item"></extend-field-list>
              <div class="middle_item">
                <span class="title">报修人</span>
                <span class="value">{{ order.create_user_real_name }}</span>
              </div>
              <div class="middle_item">
                <span class="title">报修时间</span>
                <span class="value">{{ order.created_at }}</span>
              </div>
            </div>
            <div style="border-top: none;" class="middle">
              <extend-field-list :field-list="order.finish_field_value_list" className="middle_item"></extend-field-list>
            </div>
          </div>
        </template>
        <div class="loading" v-if="firstLoading"><a-icon type="loading" /></div>
        <div class="empty_container" v-if="orderList.length === 0 && !firstLoading">
          <img src="@/assets/image/empty.png" alt="">
          <div>暂无维修历史</div>
        </div>
        <div v-if="!noMore" style="padding: 10px" class="loading_text">
          <div v-if="!isPullUpLoad">
            <span>上拉加载更多</span>
          </div>
          <div v-else>
            <span>加载中...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_container">
      <div @click="goDevice">
        <a-icon type="appstore" />
        <span>设备档案</span>
      </div>
      <div @click="inspectionPlanHistory">
        <a-icon type="interaction" />
        <span>巡检历史</span>
      </div>
      <div class="current">
        <a-icon type="tool" />
        <span>维修历史</span>
      </div>
    </div>
  </div>
</template>
<script>
import ExtendFieldList from '@/components/ExtendField/list'
import request from '@/utils/request';
import BScroll from '@better-scroll/core'
import PullDown from '@better-scroll/pull-down'
import Pullup from '@better-scroll/pull-up'
BScroll.use(PullDown)
BScroll.use(Pullup)
export default {
  components: { ExtendFieldList },
  data () {
    return {
      bscroll: null,
      beforePullDown: true,
      isPullingDown: false,
      isPullUpLoad: false,
      page: 1,
      limit: 30,
      orderList: [],
      noMore: true,
      firstLoading: true,
      qrId: 0,
      deviceId: 0
    }
  },
  created: async function () {
    this.deviceId = this.$route.query.device_id
    this.qrId = this.$route.query.qr_id
    await this.loadData()
  },
  mounted () {
    this.initBscroll()
  },
  methods: {
    inspectionPlanHistory () {
      this.$router.replace({ path: '/iph', query: { 
        device_id: this.deviceId,
        qr_id: this.qrId
      }})
    },
    goDevice () {
      this.$router.replace({ path: '/d', query: { 
        qr_id: this.qrId
      }})
    },
    async loadData (type = '') {
      await request({
        url: 'device_order/history_list',
        data: { device_id: this.deviceId,pageNo: this.page, pageSize: this.limit }
      }).then(r => {
        this.firstLoading = false
        if (r.data.data.length > 0) {
          if (type === 'refresh') {
            this.orderList = r.data.data
          } else {
            this.orderList = this.orderList.concat(r.data.data);
          }
          this.page++
          this.noMore = false
          this.$nextTick(() => {
            this.bscroll.openPullUp()
            this.bscroll.refresh()
          })
        } else {
          this.bscroll.closePullUp()
          this.noMore = true
        }
      })
    },
    initBscroll() {
      this.bscroll = new BScroll(this.$refs.wrapper, {
        scrollY: true,
        bounceTime: 800,
        useTransition: false,
        pullDownRefresh: {
          threshold: 70,
          stop: 56
        },
        listenScroll: true,
        click: true,
        pullUpLoad: true,
        mouseWheel: true
      })
      this.bscroll.on('pullingDown', this.pullingDownHandler)
      this.bscroll.on('pullingUp', this.pullingUpHandler)
    },
    async pullingDownHandler() {
      this.beforePullDown = false
      this.isPullingDown = true

      // 请求数据
      this.page = 1
      await this.loadData('refresh')

      this.isPullingDown = false
      this.finishPullDown()
    },
    finishPullDown() {
      this.bscroll.finishPullDown()
      setTimeout(() => {
        this.beforePullDown = true
        this.bscroll.refresh()
      }, 800 + 100)
    },
    async pullingUpHandler() {
      this.isPullUpLoad = true
      await this.loadData()
      if (!this.noMore) {
        this.bscroll.finishPullUp()
      }
      this.bscroll.refresh()
      this.isPullUpLoad = false
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  padding-top: 58px;
  /deep/ .ant-tabs{
    margin-bottom: 0;
    z-index: 9999;
    background-color: #fff;
  }
  /deep/ .ant-tabs-bar{
    margin-bottom: 0;
  }
  .history_item{
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    .top{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code{
        font-size: 20px;
        color: #009bfc;
      }
      .status{
        font-size: 16px;
        color: red;
      }
    }
    .middle{
      font-size: 16px;
      padding: 10px;
      border-top: 1px dotted #f1f1f1;
      border-bottom: 1px dotted #f1f1f1;
      .middle_item {
        display: flex;
        padding: 5px 0;
        .title{
          color: #999; 
          width: 80px;
        }
        .value{
          flex: 1;
          color: #222;
        }
      }
    }
    .bottom{
      font-size: 16px;
      color: #999;
      padding: 10px;
    }
  }
  .wrapper{
    position: fixed;
    left: 10px;
    bottom: 70px;
    height: calc(100% - 130px);
    width: calc(100% - 20px);
    .pulldown-wrapper{
      position: absolute;
      width: 100%;
      padding: 22px;
      box-sizing: border-box;
      transform: translateY(-100%) translateZ(0);
    }
    .loading_text{
      text-align: center;
      color: #999;
    }
  }
}
.empty_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    margin-top: 30%;
    width: 80%;
  }
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240,242,245, 1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.btn_container{
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 56px;
  height: 50px;
  border-radius: 6px;
  background-color: #ff4d4f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-size: 12px;
  z-index: 99999;
  i{
    font-size: 22px;
    margin-top: 3px;
  }
}
</style>
